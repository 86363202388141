import { motion } from "framer-motion";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { Link as ScrollLink } from "react-scroll";
import { urlFor } from "../../lib/generic";

type FooterTypes = "default";
type FooterProps = {
  type?: FooterTypes;
  socials: any;
  legal: any[];
};

function Footer({ type }: FooterProps) {
  switch (type) {
    default:
      return <Default {...arguments[0]} />;
  }
}

function Default({ socials, legal }: FooterProps) {
  const { pathname, push } = useRouter();
  const { t } = useTranslation(["common"]);

  const linkStyle = `text-base whitespace-nowrap transition select-none cursor-pointer active:text-white/50`;

  const links: {
    [key: string]: {
      [key: string]: {
        type: "scroll" | "link";
        ref: any;
      };
    };
  } = {
    [t("footer.first.label")]: {
      [t("footer.first.features")]: {
        type: "link",
        ref: "/",
      },
      [t("footer.first.security")]: {
        type: "link",
        ref: "/security",
      },
      [t("footer.first.plans")]: {
        type: "link",
        ref: "/plans",
      },
    },
    [t("footer.second.label")]: {
      [t("footer.second.phone")]: {
        type: "link",
        ref: {
          pathname: "/support",
          query: {
            method: "phone",
          },
        },
      },
      [t("footer.second.mail")]: {
        type: "link",
        ref: {
          pathname: "/support",
          query: {
            method: "mail",
          },
        },
      },

      [t("footer.second.chat")]: {
        type: "link",
        ref: {
          pathname: "/support",
          query: {
            method: "chat",
          },
        },
      },
    },
  };

  return (
    <motion.footer
      className="bg-brand-500 w-full flex flex-col p-6 space-y-6 mt-24"
      transition={{
        duration: 0,
      }}
      layout
    >
      <div
        className={`container mx-auto flex items-start justify-start flex-wrap gap-y-6`}
      >
        {/* Static */}
        {Object.entries(links).map((section, index: number) => {
          return (
            <div
              key={index}
              className={`flex-1 flex flex-col items-stretch text-white space-y-2 max-w-[15rem]`}
            >
              <span className={``}>
                <h2 className={`text-xl font-bold`}>{section[0]}</h2>
              </span>

              <span className={`flex flex-col space-y-1`}>
                {Object.entries(section[1]).map((link, index: number) => {
                  return (
                    <span key={index}>
                      {link[1].type === "link" ? (
                        <Link href={link[1].ref}>
                          <a className={linkStyle}>{link[0]}</a>
                        </Link>
                      ) : (
                        <ScrollLink
                          className={linkStyle}
                          to={link[1].ref}
                          duration={150}
                          smooth={true}
                          offset={-50}
                        >
                          {link[0]}
                        </ScrollLink>
                      )}
                    </span>
                  );
                })}
              </span>
            </div>
          );
        })}

        {/* From CMS */}
        <div
          className={`flex-1 flex flex-col items-stretch text-white space-y-2 max-w-md`}
        >
          <span className={``}>
            <h2 className={`text-xl font-bold`}>{t("footer.legal")}</h2>
          </span>

          <span className={`flex flex-col space-y-1`}>
            {legal.map((page: any, index: number) => {
              return (
                <Link key={index} href={page.link.url}>
                  <a
                    className={linkStyle}
                    title={page.link.title}
                    target={page.link.isExternal ? "_blank" : "_self"}
                    rel="noopener noreferrer"
                  >
                    {page.link.label}
                  </a>
                </Link>
              );
            })}
          </span>
        </div>

        {/* Socials */}
        {socials?.list?.length > 0 && (
          <div className={`flex-1 flex flex-col items-stretch text-white`}>
            <span className={``}>
              <h2 className={`text-xl font-bold`}>Social</h2>
            </span>

            <div className={`flex-auto flex flex-nowrap flex-shrink space-x-1`}>
              {socials.list.map(([, value]: [string, any], index: number) => {
                return (
                  <a
                    className={`text-3xl py-1 px-1 flex items-center justify-center rounded transition duration-150 active:bg-black/10`}
                    title={value.link.title}
                    rel={"noreferrer"}
                    href={value.link.url}
                    target={value.link.newTab ? "_blank" : "_self"}
                    key={index}
                  >
                    <span
                      className={`relative h-8 w-8 filter brightness-0 invert`}
                    >
                      <Image
                        alt={value.link.title}
                        src={urlFor(value.image).url()}
                        layout={`fill`}
                        objectFit={`contain`}
                      />
                    </span>
                  </a>
                );
              })}
            </div>
          </div>
        )}
      </div>

      <div
        className={
          "container mx-auto flex flex-row flex-wrap-reverse items-center space-y-reverse space-y-4 justify-between text-sm text-white/50 border-t-white/25 md:pt-2 md:space-x-1 md:-space-y-0 md:border-t"
        }
      >
        <div>
          Copyright © {new Date().getFullYear()} Hyperion Srl. All rights
          reserved.
        </div>
        <div className={`flex space-x-2 items-center justify-center `}>
          <a
            className={`cursor-pointer active:text-white`}
            onClick={async () => {
              const Cookies = (await import("js-cookie")).default;
              push(pathname, pathname, { locale: "it" });
              Cookies.set("NEXT_LOCALE", "it");
            }}
          >
            Italiano
          </a>
          <span>-</span>
          <a
            className={`cursor-pointer active:text-white`}
            onClick={async () => {
              const Cookies = (await import("js-cookie")).default;
              push(pathname, pathname, { locale: "en-US" });
              Cookies.set("NEXT_LOCALE", "en-US");
            }}
          >
            English (US)
          </a>
        </div>
      </div>
    </motion.footer>
  );
}

export default Footer;
